export default [
  { utc: 'UTC+2', label: 'UTC+2 - Калининград', value: 2 },
  { utc: 'UTC+3', label: 'UTC+3 - Москва', value: 3 },
  { utc: 'UTC+4', label: 'UTC+4 - Самара', value: 4 },
  { utc: 'UTC+5', label: 'UTC+5 - Екатеринбург', value: 5 },
  { utc: 'UTC+6', label: 'UTC+6 - Омск', value: 6 },
  { utc: 'UTC+7', label: 'UTC+7 - Красноярск', value: 7 },
  { utc: 'UTC+8', label: 'UTC+8 - Иркутск', value: 8 },
  { utc: 'UTC+9', label: 'UTC+9 - Якутск', value: 9 },
  { utc: 'UTC+10', label: 'UTC+10 - Владивосток', value: 10 },
  { utc: 'UTC+11', label: 'UTC+11 - Магадан', value: 11 },
  { utc: 'UTC+12', label: 'UTC+12 - Камчатка', value: 12 },
];
