export const typesCriteria = {
  STATUS: { id: 'ivrStatus', label: 'Статус звонка' },
  MAX_BILL_SEC: { id: 'maxBillsec', label: 'Длительность звонка ≥' },
  DIGIT: { id: 'digits', label: 'Целевое действие' },
};

export const criteriaDigits = [
  { id: '1', label: 'Нажатие на "1"' },
  { id: '2', label: 'Нажатие на "2"' },
  { id: '3', label: 'Нажатие на "3"' },
  { id: '4', label: 'Нажатие на "4"' },
  { id: '5', label: 'Нажатие на "5"' },
  { id: '6', label: 'Нажатие на "6"' },
  { id: '7', label: 'Нажатие на "7"' },
  { id: '8', label: 'Нажатие на "8"' },
  { id: '9', label: 'Нажатие на "9"' },
  { id: '0', label: 'Нажатие на "0"' },
  { id: '*', label: 'Нажатие на "*"' },
  { id: '#', label: 'Нажатие на "#"' },
];

// export const speechDigit = [
//   { id: 'да', label: 'Да' },
//   { id: 'нет', label: 'Нет' },
// ];

// export const ivrStatuses = [
//   { id: 'failure', label: 'Опции не выбраны, вызов завершился' },
//   { id: 'timeout', label: 'Вызов ушел на маршрут по-умолчанию' },
//   { id: 'success', label: 'Была выбрана опция' },
// ];

export const ivrStatuses = [
  { id: 'NORMAL_CLEARING', label: 'Успешный' },
];
